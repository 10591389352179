import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import WS from '../@core/ws'

import moment from 'moment'

// import api from '@src/api'

const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : null
}

const initialSchool = () => {
  const item = window.localStorage.getItem('schoolData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : null
}

// export const getAuth = createAsyncThunk('auth/get', async (payload, { dispatch, getState }) => {
//   const response = await useApi.getAuth()
//   return response.data
// })

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoading: false,
    user: initialUser(),
    school: initialSchool(),
    isSubscriptionExpired: false
  },
  reducers: {
    handleLogin: (state, action) => {
      if (action.payload.user) {
        state.user = action.payload.user
        localStorage.setItem('userData', JSON.stringify(action.payload.user))
      }

      if (action.payload.token) {
        localStorage.setItem('accessToken', JSON.stringify(action.payload.token))
      }

      if (action.payload.school) {
        state.school = action.payload.school

        if (action.payload.school.platformPlan) {
          if (moment().isAfter(moment(action.payload.school.platformPlan.expiredAt), 'day')) {
            state.isSubscriptionExpired = true
          } else {
            state.isSubscriptionExpired = false
          }
        } else {
          state.isSubscriptionExpired = true
        }

        localStorage.setItem('schoolData', JSON.stringify(action.payload.school))
      }

      WS.connect()
    },
    handleSetData: (state, action) => {
      if (action.payload.user) {
        state.user = action.payload.user
        localStorage.setItem('userData', JSON.stringify(action.payload.user))
      }

      if (action.payload.token) {
        localStorage.setItem('accessToken', JSON.stringify(action.payload.token))
      }

      if (action.payload.school) {
        state.school = action.payload.school

        if (action.payload.school.platformPlan) {
          if (moment().isAfter(moment(action.payload.school.platformPlan.expiredAt), 'day')) {
            state.isSubscriptionExpired = true
          } else {
            state.isSubscriptionExpired = false
          }
        } else {
          state.isSubscriptionExpired = true
        }

        localStorage.setItem('schoolData', JSON.stringify(action.payload.school))
      }
    },
    setSchoolData: (state, action) => {
      if (action.payload.school) {
        state.school = action.payload.school
        localStorage.setItem('schoolData', JSON.stringify(action.payload.school))
      }
    },
    handleLogout: state => {
      state.user = null
      state.school = null

      localStorage.removeItem('userData')
      localStorage.removeItem('schoolData')
      localStorage.removeItem('accessToken')
      WS.disconnect()
    },
    setExpireSubscription(state, action) {
      state.isSubscriptionExpired = action.payload
    }
  }
})

export const { handleLogin, handleLogout, handleSetData, setExpireSubscription, setSchoolData } = authSlice.actions

export default authSlice.reducer