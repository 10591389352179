// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'
import toast from 'react-hot-toast'

export const getMarathonsList = createAsyncThunk('student/marathons/list', async (payload, { dispatch, getState }) => {
  const response = await useApi.studentMarathonsList(getState().studentMarathonsList.params)
  return response.data
})

export const studentMarathonsSlice = createSlice({
  name: 'studentMarathonsList',
  initialState: {
    isLoading: false,
    isCreating: false,
    data: [],
    total: 0,
    params: {
      itemsPerPage: 10,
      currentPage: 1,
      q: ''
    },
  },
  reducers: {
    changeParams(state, action) {
      state.params = {...state.params, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMarathonsList.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getMarathonsList.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.total = action.payload._meta.pagination.total
      state.isLoading = false
    })
  }
})

export const { changeParams } = studentMarathonsSlice.actions

export default studentMarathonsSlice.reducer