// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getCourse = createAsyncThunk('schoolAdmin/courses/getCourse', async id => {
  const response = await useApi.schoolAdminGetCourse(id)
  return response.data.data
})

export const updateCourse = createAsyncThunk('schoolAdmin/courses/updateCourse', async (payload, { dispatch }) => {
  const response = await useApi.schoolAdminUpdateCourse(payload.courseId, payload.params)
  await dispatch(getCourse(payload.courseId))

  setTimeout(() => {
    window.location.href = `/courses/${payload.courseId}`
  }, 50)

  return response.data.data
})

export const deleteCourse = createAsyncThunk('schoolAdmin/courses/deleteCourse', async (payload, { dispatch }) => {
  const response = await useApi.schoolAdminDeleteCourse(payload.courseId)

  setTimeout(() => {
    window.location.href = `/courses`
  }, 50)

  return response.data
})

export const createCourseSection = createAsyncThunk('schoolAdmin/courses/sections/createSection', async (payload, { dispatch }) => {
  const response = await useApi.schoolAdminCreateCourseSection(payload.courseId, payload.params)
  await dispatch(getCourse(payload.courseId))
  return response.data.data
})

export const updateCourseSection = createAsyncThunk('schoolAdmin/courses/sections/updateSection', async (payload, { dispatch }) => {
  const response = await useApi.schoolAdminUpdateCourseSection(payload.courseId, payload.courseSectionId, payload.params)
  await dispatch(getCourse(payload.courseId))
  payload.closeModal()
  return response.data.data
})

export const deleteCourseSection = createAsyncThunk('schoolAdmin/courses/sections/deleteSection', async ({ courseId, courseSectionId}, { dispatch }) => {
  await useApi.schoolAdminDeleteCourseSection(courseId, courseSectionId)
  await dispatch(getCourse(courseId))

  return id
})


export const changeSectionPosition = createAsyncThunk('schoolAdmin/courses/sections/changePosition', async ({ courseId, courseSectionId, data }, { dispatch, getState }) => {
  await useApi.schoolAdminCourseSectionChangePosition(courseId, courseSectionId, data)
  await dispatch(getCourse(courseId))
})

export const changeLessonPosition = createAsyncThunk('schoolAdmin/courses/sections/changePosition', async ({ courseId, courseLessonId, data }, { dispatch, getState }) => {
  await useApi.schoolAdminCourseLessonChangePosition(courseId, courseLessonId, data)
  await dispatch(getCourse(courseId))
})

export const createCourseLesson = createAsyncThunk('schoolAdmin/courses/lessons/createLesson', async ({ courseId, params }, { dispatch }) => {
  const response = await useApi.schoolAdminCreateCourseLesson(courseId, params)
  await dispatch(getCourse(courseId))
  return response.data.data
})

export const deleteCourseLesson = createAsyncThunk('schoolAdmin/courses/lessons/deleteLesson', async ({ courseId, courseLessonId}, { dispatch }) => {
  await useApi.schoolAdminDeleteCourseLesson(courseId, courseLessonId)
  await dispatch(getCourse(courseId))

  return id
})

export const appUsersSlice = createSlice({
  name: 'schoolAdminCoursesDetail',
  initialState: {
    selectedCourse: null,
    selectedCourseId: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCourse.fulfilled, (state, action) => {
      state.selectedCourse = action.payload.course

      if (state.selectedCourse && state.selectedCourse.courseSections) {
        state.selectedCourse.courseSections = state.selectedCourse.courseSections.sort((a, b) => {
          return parseInt(a.position) - parseInt(b.position);
        })

        state.selectedCourse.courseSections = state.selectedCourse.courseSections.map((cs) => {
          if (cs.courseLessons) {
            cs.courseLessons = cs.courseLessons.sort((a, b) => {
              return parseInt(a.position) - parseInt(b.position);
            })
          }

          return cs
        })
      }

      state.selectedCourseId = action.payload.course.id
    })
  }
})

export default appUsersSlice.reducer
