// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import toast from 'react-hot-toast'

import useApi from '@src/api'

export const getHomework = createAsyncThunk('schoolAdmin/classes/homework/get', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminClassHomeworkGet(payload.classId, payload.homeworkId, {})
  return response.data
})

export const deleteExerciseFromHomework = createAsyncThunk('schoolAdmin/classes/homework/exercises/delete', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminClassHomeworkDelete(
    payload.classId,
    payload.homeworkId,
    payload.exerciseId,
    {}
  )

  await dispatch(getHomework({
    classId: payload.classId,
    homeworkId: payload.homeworkId
  }))
  
  return response.data
})

export const changeHomeworkStatus = createAsyncThunk('schoolAdmin/classes/homework/changeStatus', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminClassHomeworkChangeStatus(payload.classId, payload.homeworkId, {
    userId: payload.userId,
    status: payload.status
  })

  await dispatch(getHomework({
    classId: payload.classId,
    homeworkId: payload.homeworkId
  }))

  return response.data
})

export const removeHomework = createAsyncThunk('schoolAdmin/classes/homework/remove', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminClassHomeworkRemove(payload.classId, payload.homeworkId)

  if (payload.callback) {
    payload.callback()
  }

  return response.data
})


export const schoolAdminClassHomeworkSlice = createSlice({
  name: 'schoolAdminClassHomework',
  initialState: {
    selectedGroupUser: null,
    isLoading: false,
    userAnswers: {},
    allAnswers: {},
    class: null,
    isStatusChanging: false,
  },
  reducers: {
    
    setSelectedGroupUser(state, action) {
      state.selectedGroupUser = action.payload
      state.userAnswers =  state.allAnswers[action.payload]
    },
    
    clearExerciseAnswersState(state, action) {
      state.allAnswers[payload.params.userId][action.payload.exerciseId] = undefined
      
      if (parseInt(action.payload.params.userId) === parseInt(state.selectedGroupUser)) {
        state.userAnswers[action.payload.exerciseId] = undefined
      }
    },
    addAnswer(state, action) {
      // Group
      if (state.class.type === 'group') {
        // Selected user
        if (parseInt(action.payload.userId) === parseInt(state.selectedGroupUser)) {
          if (!action.payload.isMultipleAnswers) {
            state.userAnswers[action.payload.exerciseId] = action.payload.answer
          } else {
            if (state.userAnswers[action.payload.exerciseId]) {
              state.userAnswers[action.payload.exerciseId].push(action.payload.answer)
            } else {
              state.userAnswers[action.payload.exerciseId] = [action.payload.answer]
            }
          }
        }

        // Add to all answers
        if (!state.allAnswers[action.payload.userId]) {
          state.allAnswers[action.payload.userId] = {}
        }

        if (!action.payload.isMultipleAnswers) {
          state.allAnswers[action.payload.userId][action.payload.exerciseId] = action.payload.answer
        } else {
          if (state.allAnswers[action.payload.userId][action.payload.exerciseId]) {
            state.allAnswers[action.payload.userId][action.payload.exerciseId].push(action.payload.answer)
          } else {
            state.allAnswers[action.payload.userId][action.payload.exerciseId] = [action.payload.answer]
          }
        }
      }

      // Personal
      if (state.class.type === 'personal') {
        if (!action.payload.isMultipleAnswers) {
          state.userAnswers[action.payload.exerciseId] = action.payload.answer
        } else {
          if (state.userAnswers[action.payload.exerciseId]) {
            state.userAnswers[action.payload.exerciseId].push(action.payload.answer)
          } else {
            state.userAnswers[action.payload.exerciseId] = [action.payload.answer]
          }
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getHomework.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getHomework.fulfilled, (state, action) => {
      state.class = action.payload.data.class
      state.class.homework = action.payload.data.homework
      state.lesesonSettings = action.payload.data.lesesonSettings
      

      if (action.payload.data.class.type === 'personal') {
        state.userAnswers = action.payload.data.answers
      } else if (action.payload.data.class.type === 'group') {
        if (action.payload.data.class.group && action.payload.data.class.group.groupUsers && action.payload.data.class.group.groupUsers.length > 0) {
          state.userAnswers = action.payload.data.answers[action.payload.data.class.group.groupUsers[0].userId]
          state.selectedGroupUser = action.payload.data.class.group.groupUsers[0].userId
          state.allAnswers = action.payload.data.answers
        }
      }      

      state.isLoading = false
    })

    builder.addCase(changeHomeworkStatus.pending, (state) => {
      state.isStatusChanging = true
    })
    builder.addCase(changeHomeworkStatus.fulfilled, (state, action) => {
      state.isStatusChanging = false

      toast.success('Статус домашнього завданння успішно змінено', {
        duration: 5000
      })
    })
    builder.addCase(changeHomeworkStatus.rejected, (state, action) => {
      state.isStatusChanging = false

      toast.error('Упс! Щось пішло не так', {
        duration: 5000
      })
    })
  }
})

export const { setSelectedGroupUser, addAnswer } = schoolAdminClassHomeworkSlice.actions

export default schoolAdminClassHomeworkSlice.reducer