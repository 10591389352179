// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getMarathonsCourses = createAsyncThunk('student/marathons/courses/list', async (payload, { dispatch, getState }) => {
    const response = await useApi.studentMarathonsCourses(payload)
    return response.data
})

export const appUsersSlice = createSlice({
    name: 'studentMarathonsViewCourses',
    initialState: {
      data: [],
      kalynaAcademy: [],
      total: 0,
      params: {},
      selectedCourse: null
    },
    reducers: {
      setSelectedCourse(state, action) {
        state.selectedCourse = action.payload
      },
    },
    extraReducers: (builder) => {
      
      builder.addCase(getMarathonsCourses.fulfilled, (state, action) => {
        state.data = action.payload.data.filter(item => !item.isKalynaAcademy)
        state.kalynaAcademy = action.payload.data.filter(item => item.isKalynaAcademy)
        state.params = action.payload.params
        state.total = action.payload.total
      })
    }
  })
  
  export const { setSelectedCourse, setSelectedCourseLessonShow } = appUsersSlice.actions
  export default appUsersSlice.reducer