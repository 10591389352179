// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'
import toast from 'react-hot-toast'

export const getMarathons = createAsyncThunk('schoolAdmin/marathons/get', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminMarathonsGet(payload)
  return response.data
})

export const updateMarathons = createAsyncThunk('schoolAdmin/marathons/edit', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminMarathonsEdit(payload.marathonId, payload)

  if (payload.callback && typeof payload.callback === 'function') {
    payload.callback()
  }
  
  await dispatch(getMarathons(payload.marathonId))

  return response.data
})

export const activateMarathons = createAsyncThunk('schoolAdmin/marathons/activate', async (payload, { dispatch, getState }) => {
  try {
    const response = await useApi.schoolAdminMarathonsActivate(payload)
    
    await dispatch(getMarathons(payload))

    return response.data
  } catch (e) {
    const message =
        (e.response.data.message) ||
        e.toString();
      return rejectWithValue(message);
  }
})

export const deactivateMarathons = createAsyncThunk('schoolAdmin/marathons/deactivate', async (payload, { dispatch, getState }) => {
  try {
    const response = await useApi.schoolAdminMarathonsDeactivate(payload)
    
    await dispatch(getMarathons(payload))

    return response.data
  } catch (e) {
    const message =
        (e.response.data.message) ||
        e.toString();
      return rejectWithValue(message);
  }
})

export const payMarathons = createAsyncThunk('schoolAdmin/marathons/pay', async (payload, { dispatch, getState, rejectWithValue }) => {
  try {
    const response = await useApi.schoolAdminMarathonsPay(payload)
    
    await dispatch(getMarathons(payload))

    return response.data
  } catch (e) {
    const message =
        (e.response.data.message) ||
        e.toString();
      return rejectWithValue(message);
  }
})


export const schoolAdminMarathonsSlice = createSlice({
  name: 'schoolAdminMarathonsView',
  initialState: {
    isLoading: false,
    isUpdating: false,
    data: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMarathons.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getMarathons.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.isLoading = false
    })
    builder.addCase(updateMarathons.pending, (state) => {
      state.isUpdating = true
    })
    builder.addCase(updateMarathons.fulfilled, (state, action) => {
      state.isUpdating = false
    })
    builder.addCase(updateMarathons.rejected, (state, action) => {
      state.isUpdating = false
    })

    builder.addCase(payMarathons.rejected, (state, action) => {
      toast.error(action.payload)
    })

    builder.addCase(activateMarathons.rejected, (state, action) => {
      toast.error(action.payload)
    })

    builder.addCase(deactivateMarathons.rejected, (state, action) => {
      toast.error(action.payload)
    })
  }
})

export default schoolAdminMarathonsSlice.reducer