// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'
import toast from 'react-hot-toast'

export const getMarathons = createAsyncThunk('student/marathons/get', async (payload, { dispatch, getState }) => {
  const response = await useApi.studentMarathonsGet(payload)
  return response.data
})


export const studentMarathonsSlice = createSlice({
  name: 'studentMarathonsView',
  initialState: {
    isLoading: false,
    isUpdating: false,
    data: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMarathons.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getMarathons.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.isLoading = false
    })
  }
})

export default studentMarathonsSlice.reducer