// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getMarathonsCourses = createAsyncThunk('schoolAdmin/marathons/courses/list', async (payload, { dispatch, getState }) => {
    const response = await useApi.schoolAdminMarathonsCourses(payload)
    return response.data
})

export const toogleMarathonsCoursesLesson = createAsyncThunk('schoolAdmin/marathons/courses/lessons/toogle', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminMarathonsCoursesLessonsToogle(payload.marathonId, payload)
  return response.data
})

export const appUsersSlice = createSlice({
    name: 'schoolAdminMarathonsViewCourses',
    initialState: {
      data: [],
      kalynaAcademy: [],
      total: 0,
      params: {},
      selectedCourse: null
    },
    reducers: {
      setSelectedCourse(state, action) {
        state.selectedCourse = action.payload
      },
      setSelectedCourseLessonShow(state, action) {
        state.selectedCourse.courseSections = state.selectedCourse.courseSections.map((courseSection) => {
          courseSection.courseLessons = courseSection.courseLessons.map((lesson) => {
            console.log(lesson.id, action.payload)
            if (lesson.id === action.payload) {
              lesson.isOpened = !lesson.isOpened
            }

            return lesson
          })

          return courseSection
        })
      },
    },
    extraReducers: (builder) => {
      
      builder.addCase(getMarathonsCourses.fulfilled, (state, action) => {
        state.data = action.payload.data.filter(item => !item.isKalynaAcademy)
        state.kalynaAcademy = action.payload.data.filter(item => item.isKalynaAcademy)
        state.params = action.payload.params
        state.total = action.payload.total
      })

      
      
    }
  })
  
  export const { setSelectedCourse, setSelectedCourseLessonShow } = appUsersSlice.actions
  export default appUsersSlice.reducer