// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'
import toast from 'react-hot-toast'

export const getMarathonsEvents = createAsyncThunk('schoolAdmin/marathons/events/list', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminMarathonsEvents(payload, getState().schoolAdminMarathonsViewEvents.params)
  return response.data
})

export const deleteMarathonsEvent = createAsyncThunk('schoolAdmin/marathons/events/delete', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminMarathonsDeleteEvent(
    payload.marathonId, payload.eventId, {}
  )

  await dispatch(getMarathonsEvents(payload.marathonId))
  return response.data
})



export const schoolAdminMarathonsViewEventsSlice = createSlice({
  name: 'schoolAdminMarathonsViewEvents',
  initialState: {
    isLoading: false,
    data: [],
    params: {
      itemsPerPage: 10,
      currentPage: 1,
      q: ''
    }
  },
  reducers: {
    changeParams(state, action) {
      state.params = {...state.params, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMarathonsEvents.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getMarathonsEvents.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.total = action.payload._meta.pagination.total
      state.isLoading = false
    })
    builder.addCase(deleteMarathonsEvent.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(deleteMarathonsEvent.fulfilled, (state) => {
      state.isLoading = false
    })
    
  }
})

export const { changeParams } = schoolAdminMarathonsViewEventsSlice.actions

export default schoolAdminMarathonsViewEventsSlice.reducer