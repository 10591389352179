// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getData = createAsyncThunk('schoolAdmin/courses/getCourses', async params => {
  const response = await useApi.schoolAdminGetCourses(params)
  return {
    params,
    data: response.data.data,
    total: response.data['_meta']['pagination']['total'] || 0
  }
})

export const addCourse = createAsyncThunk('schoolAdmin/courses/addCourse', async (data) => {
  const response = await useApi.schoolAdminCreateCourse(data)
  return response.data.data
})

export const deleteCourse = createAsyncThunk('schoolAdmin/courses/deleteCourse', async (id, { dispatch, getState }) => {
  await useApi.schoolAdminDeleteCourse(id)
  await dispatch(getData(getState().schoolAdminCourses.params))

  return id
})

export const changePosition = createAsyncThunk('schoolAdmin/courses/changePosition', async (data, { dispatch, getState }) => {
  await useApi.schoolAdminChangePositionCourse(data)
  await dispatch(getData(getState().schoolAdminCourses.params))
})

export const appUsersSlice = createSlice({
  name: 'schoolAdminCourses',
  initialState: {
    data: [],
    kalynaAcademy: [],
    total: 0,
    params: {},
    selectedCourse: null
  },
  reducers: {},
  extraReducers: (builder) => {
    
    builder.addCase(getData.fulfilled, (state, action) => {
      console.log('test', action)

      state.data = action.payload.data.filter(item => !item.isKalynaAcademy)
      state.kalynaAcademy = action.payload.data.filter(item => item.isKalynaAcademy)
      state.params = action.payload.params
      state.total = action.payload.total
    })
    
  }
})

export default appUsersSlice.reducer
