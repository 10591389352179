// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'
import toast from 'react-hot-toast'

export const getMarathonsEvents = createAsyncThunk('student/marathons/events/list', async (payload, { dispatch, getState }) => {
  const response = await useApi.studentMarathonsEvents(payload, getState().studentMarathonsViewEvents.params)
  return response.data
})

export const studentMarathonsViewEventsSlice = createSlice({
  name: 'studentMarathonsViewEvents',
  initialState: {
    isLoading: false,
    data: [],
    params: {
      itemsPerPage: 10,
      currentPage: 1,
      q: ''
    }
  },
  reducers: {
    changeParams(state, action) {
      state.params = {...state.params, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMarathonsEvents.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getMarathonsEvents.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.total = action.payload._meta.pagination.total
      state.isLoading = false
    })
  }
})

export const { changeParams } = studentMarathonsViewEventsSlice.actions

export default studentMarathonsViewEventsSlice.reducer