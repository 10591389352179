// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getData = createAsyncThunk('schoolAdmin/courses/getCoursesKalynaAcademy', async params => {
  const response = await useApi.schoolAdminGetCoursesKalynaAcademy(params)
  return {
    params,
    data: response.data.data,
    total: response.data['_meta']['pagination']['total'] || 0
  }
})

export const addCourse = createAsyncThunk('schoolAdmin/courses/addCourseKalynaAcademy', async (data) => {
  const response = await useApi.schoolAdminCreateCourse(data)
  return response.data.data
})

export const appUsersSlice = createSlice({
  name: 'schoolAdminCoursesKalynaAcademy',
  initialState: {
    data: [],
    total: 0,
    params: {}
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.total
    })
    
  }
})

export default appUsersSlice.reducer
