// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('schoolAdmin/admins/getData', async params => {
  const response = await useApi.schoolAdminGetAdmins(params)

  return {
    params,
    data: response.data.data.rows,
    permissions: response.data.data.permissions,
    totalPages: response.data['_meta']['pagination']['total'] || 0
  }
})

export const getUser = createAsyncThunk('schoolAdmin/admins/getUser', async id => {
  const response = await useApi.schoolAdminGetAdmin(id)
  return response.data.data
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  return user
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  return id
})

export const appUsersSlice = createSlice({
  name: 'schoolAdminAdmins',
  initialState: {
    data: [],
    allPermissions: [],
    total: 0,
    params: {},
    selectedUser: null,
    permissions: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.allPermissions = action.payload.permissions
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload.user,
        state.permissions = action.payload.permissions
      })
  }
})

export default appUsersSlice.reducer
