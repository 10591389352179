// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'
import toast from 'react-hot-toast'

export const getClassCourses = createAsyncThunk('teacher/classes/courses', async (payload, { dispatch, getState }) => {
  const response = await useApi.teacherClassCoursesList(payload, getState().teacherClassCourses.params)
  return response.data
})

export const getClassCourseLesson = createAsyncThunk('teacher/classes/courses/lesson', async (payload, { dispatch, getState }) => {
  const response = await useApi.teacherClassCoursesGet(payload.courseId, payload.lessonId)
  return response.data
})

export const classCourseShare = createAsyncThunk('teacher/classes/courses/share', async (payload, { dispatch, getState }) => {
  const response = await useApi.teacherClassCourseShare(payload.classId, {
    type: payload.type,
    sectionId: payload.sectionId,
    lessonId: payload.lessonId
  })
  return response.data
})

export const teacherClassesSlice = createSlice({
  name: 'teacherClassCourses',
  initialState: {
    selectedCourse: null,
    selectedLesson: null,
    selectedLessonSection: null,
    isModalOpened: false,
    isLoading: false,
    isLessonLoading: false,
    data: [],
    kalynaAcademy: [],
    total: 0,
    params: {
      itemsPerPage: 10,
      currentPage: 1,
      q: ''
    },
  },
  reducers: {
    changeParams(state, action) {
      state.params = {...state.params, ...action.payload }
    },
    openModal(state, action) {
      state.isModalOpened = true
    },
    closeModal(state, action) {
      state.isModalOpened = false
      state.selectedCourse = null
      state.selectedLesson = null
      state.selectedLessonSection = null
    },
    setSelectedCourse(state, action) {
      state.selectedCourse = action.payload
      state.selectedLesson = null
      state.selectedLessonSection = null
    },
    setSelectedLesson(state, action) {
      state.selectedLesson = action.payload
      state.selectedLessonSection = null
    },
    setSelectedLessonSection(state, action) {
      state.selectedLessonSection = action.payload
    },
    getBack(state, action) {
      if (state.selectedLesson && state.selectedLesson) {
        state.selectedLesson = null
        state.selectedLessonSection = null
      } else if (state.selectedCourse && !state.selectedLesson) {
        state.selectedCourse = null
      } else if (!state.selectedCourse && !state.selectedLesson) {
        state.isModalOpened = false
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getClassCourses.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getClassCourseLesson.pending, (state) => {
      state.isLessonLoading = true
    })
    builder.addCase(getClassCourses.fulfilled, (state, action) => {
      state.data = action.payload.data.filter((item) => !item.isKalynaAcademy)
      state.kalynaAcademy = action.payload.data.filter((item) => item.isKalynaAcademy)
      state.total = action.payload._meta.pagination.total
      state.isLoading = false
    })
    builder.addCase(getClassCourseLesson.fulfilled, (state, action) => {
      state.selectedLesson = action.payload.data.lesson

      if (action.payload.data.lesson.sections && action.payload.data.lesson.sections.length > 0) {
        state.selectedLessonSection = action.payload.data.lesson.sections[0].id
      }
      
      state.isLessonLoading = false
    })
    builder.addCase(classCourseShare.fulfilled, (state, action) => {
      toast.success('Доступ надано', {
        duration: 5000
      })
    })
    builder.addCase(classCourseShare.rejected, (state, action) => {
      toast.error('Не вдалось надати доступ', {
        duration: 5000
      })
    })
  }
})

export const { changeParams, openModal, closeModal, setSelectedCourse, setSelectedLessonSection, getBack } = teacherClassesSlice.actions

export default teacherClassesSlice.reducer