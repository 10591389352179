// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'
import toast from 'react-hot-toast'

export const getHandbook = createAsyncThunk('teacher/handbook/get', async (payload, { dispatch, getState }) => {
  const response = await useApi.teacherHandbookGet(payload)
  return response.data.data
})

export const setActiveCourseLessonSection = createAsyncThunk('teacher/handbook/setActiveSection', async sectionId => {
  return sectionId
})

export const teacherHandbookSlice = createSlice({
  name: 'teacherHandbookView',
  initialState: {
    isLoading: true,
    selectedLesson: null,
    selectedSection: null,
    editableExercise: null,
    isCreateModalOpened: false,
    isCreating: false,
    isEditing: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getHandbook.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getHandbook.fulfilled, (state, action) => {
        state.isLoading = false
        state.selectedLesson = action.payload
        state.selectedLesson.lesson.sections = state.selectedLesson.lesson.sections.sort((a, b) => parseInt(a.position) - parseInt(b.position))
        
        if (!state.selectedSection) {
          state.selectedSection = action.payload.lesson.sections.length > 0 ? action.payload.lesson.sections[0].id : null
        } else {
          const isSectionExist = action.payload.lesson.sections.length > 0 && action.payload.lesson.sections.find(s => s.id === state.selectedSection) !== undefined

          if (!isSectionExist) {
            state.selectedSection = action.payload.lesson.sections.length > 0 ? action.payload.lesson.sections[0].id : null
          }
        }
      })
      .addCase(setActiveCourseLessonSection.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(setActiveCourseLessonSection.fulfilled, (state, action) => {
        state.isLoading = false
        state.selectedSection = action.payload
      })
  }
})

export const { changeParams } = teacherHandbookSlice.actions

export default teacherHandbookSlice.reducer