// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getLearnedLessonsList = createAsyncThunk('schoolAdmin/allClasses/learnedLessons/list', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminAllClassesLearnedLessonsList(payload, getState().schoolAdminAllClassesLearnedLessonsBlock.params)
  return response.data
})

export const schoolAdminAllClassesLearnedLessonsBlockSlice = createSlice({
  name: 'schoolAdminAllClassesLearnedLessonsBlock',
  initialState: {
    isLoading: true,
    data: [],
    total: 0,
    params: {
      itemsPerPage: 10,
      currentPage: 1
    },
  },
  reducers: {
    changeParams(state, action) {
      state.params = {...state.params, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLearnedLessonsList.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getLearnedLessonsList.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.total = action.payload._meta.pagination.total
      state.isLoading = false
    })
    
  }
})

export const { changeParams } = schoolAdminAllClassesLearnedLessonsBlockSlice.actions

export default schoolAdminAllClassesLearnedLessonsBlockSlice.reducer