// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import toast from 'react-hot-toast'

import useApi from '@src/api'

import { closeModal } from './coursesModal/store'
import WS from '../../../../@core/ws'

export const getClass = createAsyncThunk('schoolAdmin/allClasses/get', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminAllClassesGet(payload)
  return response.data
})



export const getVocabulary = createAsyncThunk('schoolAdmin/allClasses/vocabulary', async (payload, { dispatch, getState }) => {
  const response = await useApi.teacherVocabularyList(getState().schoolAdminAllClassesView.vocabulary.params)
  return response
})


export const schoolAdminAllClassesViewSlice = createSlice({
  name: 'schoolAdminAllClassesView',
  initialState: {
    selectedGroupUser: null,
    selectedSection: null,
    isLoading: false,
    isEndGroupLessonModalOpened: false,
    isHomeWorkCreating: false,
    userAnswers: {},
    allAnswers: {},
    lesesonSettings: [],
    class: null,
    total: 0,
    params: {},
    vocabulary: {
      params: {
        itemsPerPage: 10,
        currentPage: 1,
        q: '',
        isLoadMore: false
      },
      items: [],
      isLoading: false,
      isMorePages: false
    }
  },
  reducers: {
    changeParams(state, action) {
      state.params = {...state.params, ...action.payload }
    },
    changeVocabulary(state, action) {
      state.vocabulary = {
        ...state.vocabulary,
        ...action.payload
      }
    },
    setActiveCourseLessonSection(state, action) {
      state.selectedSection = action.payload
    },
    setSelectedGroupUser(state, action) {
      state.selectedGroupUser = action.payload
      state.userAnswers =  state.allAnswers[action.payload]
    },
    setEndGroupLessonModalOpened(state, action) {
      state.isEndGroupLessonModalOpened = action.payload
    },
    clearExerciseAnswersState(state, action) {
      if (state.class.type === 'personal') {
        state.userAnswers[action.payload.exerciseId] = undefined
      } else {
        state.allAnswers[payload.params.userId][action.payload.exerciseId] = undefined

        if (parseInt(action.payload.params.userId) === parseInt(state.selectedGroupUser)) {
          state.userAnswers[action.payload.exerciseId] = undefined
        }
      }
    },
    changeExerciseSetting(state, action) {
      const isExerciseExist = state.lesesonSettings.find(
        ls => parseInt(ls.classId) === parseInt(action.payload.classId) &&
          parseInt(ls.lessonId) === parseInt(action.payload.lessonId) &&
          parseInt(ls.exerciseId) === parseInt(action.payload.exerciseId) &&
          ls.key === action.payload.params.key
      )

      if (isExerciseExist) {
        state.lesesonSettings = state.lesesonSettings.map((ls) => {
          if (
            parseInt(ls.classId) === parseInt(action.payload.classId) &&
            parseInt(ls.lessonId) === parseInt(action.payload.lessonId) &&
            parseInt(ls.exerciseId) === parseInt(action.payload.exerciseId) &&
            ls.key === action.payload.params.key
          ) {
            ls.value = parseInt(action.payload.params.value)
          }

          return ls
        })
      } else {
        state.lesesonSettings = [
          ...state.lesesonSettings,
          {
            classId: action.payload.classId,
            lessonId: action.payload.lessonId,
            exerciseId: action.payload.exerciseId,
            key: action.payload.params.key,
            value: action.payload.params.value
          }
        ]
      }
    },
    addTeacherAnswer(state, action) {
      if (!action.payload.isMultipleAnswers) {
        state.userAnswers[action.payload.exerciseId] = action.payload.answer
      } else {
        if (state.userAnswers[action.payload.exerciseId]) {
          state.userAnswers[action.payload.exerciseId].push(action.payload.answer)
        } else {
          state.userAnswers[action.payload.exerciseId] = [action.payload.answer]
        }
      }

      if (state.class.type === 'group') {
        // Update all answers
        for (let studentId of Object.keys(state.allAnswers)) {
          // Add to all answers
          if (!state.allAnswers[studentId]) {
            state.allAnswers[studentId] = {}
          }

          if (!action.payload.isMultipleAnswers) {
            state.allAnswers[studentId][action.payload.exerciseId] = action.payload.answer
          } else {
            if (state.allAnswers[studentId][action.payload.exerciseId]) {
              state.allAnswers[studentId][action.payload.exerciseId].push(action.payload.answer)
            } else {
              state.allAnswers[studentId][action.payload.exerciseId] = [action.payload.answer]
            }
          }
        }
      }
    },
    addAnswer(state, action) {
      // Group
      if (state.class.type === 'group') {
        // Selected user
        if (parseInt(action.payload.userId) === parseInt(state.selectedGroupUser)) {
          if (!action.payload.isMultipleAnswers) {
            state.userAnswers[action.payload.exerciseId] = action.payload.answer
          } else {
            if (state.userAnswers[action.payload.exerciseId]) {
              state.userAnswers[action.payload.exerciseId].push(action.payload.answer)
            } else {
              state.userAnswers[action.payload.exerciseId] = [action.payload.answer]
            }
          }
        }

        // Add to all answers
        if (!state.allAnswers[action.payload.userId]) {
          state.allAnswers[action.payload.userId] = {}
        }

        if (!action.payload.isMultipleAnswers) {
          state.allAnswers[action.payload.userId][action.payload.exerciseId] = action.payload.answer
        } else {
          if (state.allAnswers[action.payload.userId][action.payload.exerciseId]) {
            state.allAnswers[action.payload.userId][action.payload.exerciseId].push(action.payload.answer)
          } else {
            state.allAnswers[action.payload.userId][action.payload.exerciseId] = [action.payload.answer]
          }
        }
      }

      // Personal
      if (state.class.type === 'personal') {
        if (!action.payload.isMultipleAnswers) {
          state.userAnswers[action.payload.exerciseId] = action.payload.answer
        } else {
          if (state.userAnswers[action.payload.exerciseId]) {
            state.userAnswers[action.payload.exerciseId].push(action.payload.answer)
          } else {
            state.userAnswers[action.payload.exerciseId] = [action.payload.answer]
          }
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getClass.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getClass.fulfilled, (state, action) => {
      state.class = action.payload.data.class
      state.lesesonSettings = action.payload.data.lesesonSettings
      

      if (action.payload.data.class.type === 'personal') {
        state.userAnswers = action.payload.data.answers
      } else if (action.payload.data.class.type === 'group') {
        if (action.payload.data.class.group && action.payload.data.class.group.groupUsers && action.payload.data.class.group.groupUsers.length > 0) {
          state.userAnswers = action.payload.data.answers[action.payload.data.class.group.groupUsers[0].userId]
          state.selectedGroupUser = action.payload.data.class.group.groupUsers[0].userId
          state.allAnswers = action.payload.data.answers
        }
      }

      if (state.class.courseLesson) {
        state.class.courseLesson.sections = state.class.courseLesson.sections.sort((a, b) => parseInt(a.position) - parseInt(b.position))

        if (!state.selectedSection) {
          state.selectedSection = state.class.courseLesson.sections.length > 0 ? state.class.courseLesson.sections[0].id : null
        } else {
          const isSectionExist = state.class.courseLesson.sections.length > 0 && state.class.courseLesson.sections.find(s => s.id === state.selectedSection) !== undefined

          if (!isSectionExist) {
            state.selectedSection = state.class.courseLesson.sections.length > 0 ? state.class.courseLesson.sections[0].id : null
          }
        }
      }
      

      state.isLoading = false
    })

    builder.addCase(getVocabulary.pending, (state) => {
      state.vocabulary.isLoading = true
    })
    builder.addCase(getVocabulary.fulfilled, (state, action) => {
      if (state.vocabulary.params.isLoadMore) {
        state.vocabulary.items = state.vocabulary.items.concat(action.payload.data.data)
        state.vocabulary.params.isLoadMore = false
      } else {
        state.vocabulary.items = action.payload.data.data
      }

      state.vocabulary.isLoading = false
      state.vocabulary.isMorePages = parseInt(action.payload.data._meta.pagination.total) > parseInt(state.vocabulary.params.itemsPerPage) * parseInt(state.vocabulary.params.currentPage)
    })
    
  }
})

export const { changeParams, setActiveCourseLessonSection, setSelectedGroupUser, setEndGroupLessonModalOpened, addAnswer, addTeacherAnswer, changeExerciseSetting, changeVocabulary, clearExerciseAnswersState } = schoolAdminAllClassesViewSlice.actions

export default schoolAdminAllClassesViewSlice.reducer