// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'
import toast from 'react-hot-toast'

export const getMarathonsMemberModules = createAsyncThunk('schoolAdmin/marathons/members/modules/list', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminMarathonsMembersModules(payload.marathonId, payload.userId)
  
  return response.data
})

export const toggleMarathonsMemberModule = createAsyncThunk('schoolAdmin/marathons/members/modules/list', async (payload, { dispatch, getState }) => {
  try {
    const response = await useApi.schoolAdminMarathonsMembersModulesToggle(payload.marathonId, payload.userId, payload.sectionId)
    
    dispatch(getMarathonsMemberModules({
      marathonId: payload.marathonId, 
      userId: payload.userId
    }))

    return response.data
  } catch (e) {
    console.log(e)
  }
})

// export const deleteMarathonsMember = createAsyncThunk('schoolAdmin/marathons/members/delete', async (payload, { dispatch, getState }) => {
//   const response = await useApi.schoolAdminMarathonsMembersDelete(
//     payload.marathonId, payload.memberId, {}
//   )

//   await dispatch(getMarathonsMembers(payload.marathonId))
//   return response.data
// })

// export const makePaymentMarathonsMember = createAsyncThunk('schoolAdmin/marathons/members/makePayment', async (payload, { dispatch, getState }) => {
//   const response = await useApi.schoolAdminMarathonsMembersMakePayment(
//     payload.marathonId, payload.memberId, {}
//   )

//   await dispatch(getMarathonsMembers(payload.marathonId))
//   return response.data
// })

export const schoolAdminMarathonsViewMembersModulesSlice = createSlice({
    name: 'schoolAdminMarathonsViewMembersModules',
    initialState: {
      isLoading: true,
      data: [],
      params: {
        itemsPerPage: 10,
        currentPage: 1,
        q: ''
      }
    },
    reducers: {
      changeParams(state, action) {
        state.params = {...state.params, ...action.payload }
      },
    },
    extraReducers: (builder) => {
      builder.addCase(getMarathonsMemberModules.pending, (state) => {
        state.isLoading = true
      })
      builder.addCase(getMarathonsMemberModules.fulfilled, (state, action) => {
        state.data = action.payload.data
        //state.total = action.payload._meta.pagination.total
        state.isLoading = false
      })
    //   builder.addCase(deleteMarathonsMember.pending, (state) => {
    //     state.isLoading = true
    //   })
    //   builder.addCase(deleteMarathonsMember.fulfilled, (state) => {
    //     state.isLoading = false
    //   })
    //   builder.addCase(makePaymentMarathonsMember.pending, (state) => {
    //     state.isLoading = true
    //   })
    //   builder.addCase(makePaymentMarathonsMember.fulfilled, (state) => {
    //     state.isLoading = false
    //   })
    }
  })
  
  export const { changeParams } = schoolAdminMarathonsViewMembersModulesSlice.actions
  
  export default schoolAdminMarathonsViewMembersModulesSlice.reducer